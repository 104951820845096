// extracted by mini-css-extract-plugin
export var names = "styles-module--names--2TTaW";
export var firstname = "styles-module--firstname--1KYPx";
export var message = "styles-module--message--HowvY";
export var big = "styles-module--big--fzMPB";
export var button = "styles-module--button--Ci2DT";
export var promoCard = "styles-module--promoCard--1JeA7";
export var image = "styles-module--image--TS5Nc";
export var label = "styles-module--label--2Tz_U";
export var link = "styles-module--link--gSfYk";
export var subHead = "styles-module--subHead--1g1IQ";
export var title = "styles-module--title--2xLDl";
export var dek = "styles-module--dek--3EsNQ";
export var date = "styles-module--date--krdKN";
export var stylesLeadership = "styles-module--stylesLeadership--12cPu";
export var styleOverlay = "styles-module--styleOverlay--xHz7_";
export var infoContainer = "styles-module--infoContainer--9Asyg";
export var styleInline = "styles-module--styleInline--32tMM";
export var infoContainerText = "styles-module--infoContainerText--2y0Ah";
export var pressCardStyle = "styles-module--pressCardStyle--39j-B";
export var bounce = "styles-module--bounce--1lcHf";