import React from 'react';
import * as styles from './styles.module.scss';

const AdditionalInfo = ({
    buttons
  }) => (

        <div className={styles.allbuttons}> {
            buttons.map((item) => (
                <div key={item.id}>
                    <form action={item.filename.publicURL} target="_blank">
                        <button type="submit" className={styles.button} target="_blank">{item.label}</button>
                    </form>
                </div>
            ))
        }
        </div>
        );

export default AdditionalInfo;


