// Conditional wrapper component.
// For example: Conditionally wrap children within <a> tags if URL exists.

// Render wrapping elements based on a condition.
// This functional component can determine
// when(condition) and how(wrapper) should the children be wrapped.

const ConditionalWrap = ({ condition, wrap, children }) => (
  condition ? wrap(children) : children
);

export default ConditionalWrap;
