import * as React from "react"
import Layout from "../../components/Layout";
import { graphql, useStaticQuery } from "gatsby";
import Hero from "../../components/Hero";
import InfoForm from "../../components/InfoForm";
import Description from "../../components/Description";
import AdditionalInfo from "../../components/AdditionalInfo";
import * as styles from "../../components/AdditionalInfo/styles.module.scss"

const WardrobePage = () => {
  const data = useStaticQuery(graphql`
    query {
      wardrobeJson {
        pageHero {
          title
          dek
          gallery {
            id
            video
            image {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        content {
          title
          email
          number
          paragraph
          button {
            name
            url
          }
          additionalInfo {
            id
            label
            filename {
              publicURL
            }
          }
        }
      }
    }
  `);

  const pageData = data.wardrobeJson;
    
  return (
    <main>
      <title>{pageData.pageHero.title}</title>
      <Layout false>
        <Hero 
          title={pageData.pageHero.title}
          gallery={pageData.pageHero.gallery}
        />
        <div style={{'margin-top': '70px'}}>
        </div>
        
        <Description bodyText={pageData.content.paragraph}></Description>
        <div style={{'text-align': 'center'}}>
          <form action={pageData.content.button.url} target="_blank">
          <button type="submit" target="_blank" className={styles.button}>{pageData.content.button.name}</button>
          </form>
        </div>
        <InfoForm
          className="layout__section-spacing"
          variant="styleInline"
          title={pageData.content.title}
          number={pageData.content.number}
          email={pageData.content.email}
          addInfo={pageData.content.additionalInfo}
          url="mailto:wardrobe.rental@telemundo.com?subject=[tcstudioservices] Contact - Wardrobe Inquiries"
        />
        
      </Layout>
    </main>
  );
};

export default WardrobePage;