import React from 'react';
import * as styles from './styles.module.scss';
import LinkElement from '../LinkElement';
import ConditionalWrap from '../ConditionalWrap';
import AdditionalInfo from '../AdditionalInfo';

const InfoForm = ({
    variant, className, title, number, url, email, addInfo
  }) => (
        <html>
        <div className={[styles.promoCard, styles[variant], className].join(' ')}>
            <AdditionalInfo buttons={addInfo}/>

            <div className = {styles.infoContainer}>

                <div className={styles.infoContainerText}>
                    <h2 className={styles.title}>
                        <ConditionalWrap condition={url} wrap={(children) => <a href={url}>{children}</a>}>
                        {title}
                        </ConditionalWrap>
                    </h2>
                    <h3 className={styles.subHead}>{number}</h3>

                    <a href={url} className={styles.link} text={email} >{email}</a>
                </div>
                

                {/* this is the form part  */}
                <form action={url} method="post" enctype="text/plain" name="contactform">
                    <textarea type="text" className={styles.firstname} placeholder="Full Name" name="Name"/>
                    <textarea type="text" className = {styles.firstname} name = "Contact Email" placeholder="Email Address"/>
                    <textarea type="text" className = {styles.message} name="Message" placeholder="Message"/>
                    <input type="submit" value="Submit" className={styles.button}/>
                </form>
            </div>
        </div>
        </html>
        );

export default InfoForm;